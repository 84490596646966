import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme, GlobalStyles } from "../themes.js";
import { useHistory } from "react-router-dom";
import "../special-pages/styles/fit-for-job.scss";
import BackToTopButton from '../component/back-to-top.js';

import { useParams } from "react-router-dom";
import fitForJob from "../assets/img/fit-for-job.png";
import meo1 from "../assets/img/meo1.png"
import meo2 from "../assets/img/meo2.png"
import meo3 from "../assets/img/meo3.png"
import meo4 from "../assets/img/meo4.png"
import meo5 from "../assets/img/meo5.png"
import meo6 from "../assets/img/meo6.png"
import meo7 from "../assets/img/meo7.png"
import meo8 from "../assets/img/meo8.png"
import meo9 from "../assets/img/meo9.png"

function FitForJob(themeData) {
  useEffect(() => {
    document.title = "Fit For The Job • Miravite Consulting Group";
  }, []);

  // function to navigate to path
  const history = useHistory();

  return (
    <ThemeProvider theme={themeData.theme === "light" ? lightTheme : darkTheme}>
    <GlobalStyles />
    <BackToTopButton />


      <div id="fit">
        <section className="fit">
          <div className="fit-container">
            <div className="article-top-margin"></div>

            <div className="fit-article">
              <div className="back-button" onClick={() => history.goBack()}>
                <button className="button">
                  <span>Go Back</span>
                  <span className="back-arrow">
                    <svg
                      height="15"
                      viewBox="0 0 24 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="arrow-right"
                        d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z"
                      />
                    </svg>
                  </span>
                </button>
              </div>

              <div className="fit-article-banner-container">
                <img src={fitForJob} />
              </div>
              {/* <span className='article-date'></span> */}
              <span className="reader-article-headline">Fit For The Job</span>
              <span className="article-author">By Romeo Abada, M.E. 1984</span>

              <div className="article-body">
                <div className="article-body-row">
                  <img src={meo1} />
                  <div className="article-body-p-container-right">
                    <p>My batch had the misfortune of entering the job market at a time when the Philippine economy was in the doldrums. Ninoy Aquino had been assassinated a year prior, and the nation was on the cusp of massive change. I, as with the rest of my batchmates, did not have the luxury of job choice. I took what was immediately on offer, an actuarial analyst role at a life insurance company.</p>
                  </div>
                </div>
                <div className="article-body-row">
                  <div className="article-body-p-container-left">
                    <p>Lack of choice notwithstanding, the insurance industry provided the perfect environment to hone the strong Math skills that I knew I possessed. I quickly enrolled for a master's degree in Math at UP, choosing to focus on all insurance-related Math subjects, and eventually embarking on a path to become a Fellow of the Actuarial Society of the Philippines. The motivation was a salary increase each time I passed an exam. In four years, I earned my license. It was early days for this profession, with very few making up this exclusive club. Not content with this local license, I went on to take, one by one, the ten US actuarial licensure exams. Study conditions were far from ideal, as it was during the power crisis of the early 1990s. In six years, I gained the US pedigree. By then, I had moved up and across to Philamlife, the country's leading insurance firm.</p>
                  </div>
                  <img src={meo2} />
                </div>
                <div className="article-body-row">
                  <img src={meo3} />
                  <div className="article-body-p-container-right">
                    <p>Armed with the credentials that were a fitting reward for so many years of hard work and grit, I progressed into an expatriate stint. My first overseas assignment was Indonesia, followed by Vietnam, and Hong Kong. I enjoyed not only the challenges of the job but also the important-sounding titles and the comforts and perks of an expat lifestyle. Actuaries, special as we are, could name our price. This was a far cry from the no-job-choice condition I had first encountered in my career.</p>
                  </div>
                </div>
                <div className="article-body-col">
                  <div className="article-body-p-container-left">
                    <p>I came home in 2009, choosing to cut short that enjoyable expat adventure to spend time with and care for my father. I re-entered the local insurance industry, taking on senior executive roles in consulting firms, Mercer and SGV-EY.</p>
                  </div>
                  <img src={meo4} />
                </div>
                <div className="article-body-row">
                  <img src={meo5} />
                  <div className="article-body-p-container-right">
                    <p>At about this time, I also found myself seeking for a new purpose, new adventures. I joined fun runs and leveled up to half-marathons in 2011. I entered with no desire to push myself to set any personal records, but simply to have fun and to relish the finisher poses (which I made sure was recorded for posterity by hiring/bribing my nephews and nieces to be present at the finish line with their phones and cameras!).</p>
                  </div>
                </div>
                <div className="article-body-col">
                  <div className="article-body-p-container-left">
                    <p>This interest eventually intensified into triathlons (so far, I have done 9), and even Spartan obstacle races, which I successfully hurdled despite being at least 25 years too late. My most recent accomplishment was a podium finish at the Aquathlon — I finished first because I was the only one in my real age group!</p>
                  </div>
                  <img src={meo6} />
                </div>
                <div className="article-body-row">
                  <div className="article-body-p-container-left">
                    <p>At present, I am CEO of Miravite, a consulting company that specializes in actuarial and strategic management services for corporate retirement benefits and life & health insurance products. It is a whole new challenge in itself, running a business with full P&L responsibilities, growing it, and steering a team of 50-plus individuals towards a common vision of being the most respected actuarial firm in the country.</p>
                  </div>
                  <img src={meo7} />
                </div>
                <div className="article-body-row">
                  <img src={meo8} />
                  <div className="article-body-p-container-right">
                    <p>My journey has been a fun and fulfilling one. I am happy with the choices I have made. The outcome of these choices is where I am right now — a healthy and fit CEO confident to claim that I am 'best in class' (ref Aquathlon story ;D). It all goes back to my ME background. I don't think I can lay claim to being one of the best actuaries in the Philippines today if not for that. ME gave me the ability to think strategically, to have breadth as well as depth, to be simultaneously broad-minded and specialized, to have richly-layered insights and solutions. Not just to business problems but to all of life's challenges.</p>
                  </div>
                </div>
                <div className="article-body-row">
                  <div className="article-body-p-container-left">
                    <p>In school, from first grade till university, every class had at least one, that Math whiz who was steps ahead in every flash cards competition in the classroom; who scored more than perfect in exams because bonus challenge questions were easily unlocked for extra points; who was first to solve complicated equations because he or she had no need for pen and paper; and who went out of his or her way to load up each semester with as many Math classes as possible to collect as many A's as possible. I know that in my Management Engineering batch of 1984, as perhaps with all batches of this elite program known to attract the numerically-inclined brains, we had more than one. But Romeo Abada, Meo as we call him, easily shone as among the brightest bulbs in a brightly lit room.</p>
                  </div>
                  <img src={meo9} />
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </ThemeProvider>
  );
}

export default FitForJob;
