import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { Link } from 'react-router-dom';
import './styles/about.scss'
import BackToTopButton from '../component/back-to-top.js';
import { HashLink } from 'react-router-hash-link';

import feliciano from '../assets/img/feliciano.png'
import faye from '../assets/img/faye.png'
import romeo from '../assets/img/romeo.png'
import team from '../assets/img/team.png'
import banner from '../assets/img/banner3.jpg'
import discipline from '../assets/img/discipline.jpg'
import excellence from '../assets/img/excellence.jpg'
import equilibrium from '../assets/img/equilibrium.jpg'
import passiom from '../assets/img/passion.jpg'
import meoStory from '../assets/img/meo-story.png'
import ffStory from '../assets/img/ff-story.png'

import founderStory from '../assets/img/founders-story.png'
import fm1 from "../assets/img/fm1.jpg"
import fm2 from "../assets/img/fm2.jpg"
import fm5 from "../assets/img/fm5.jpg"

import fitForJob from "../assets/img/fit-for-job.png";
import meo1 from "../assets/img/meo1.png"
import meo2 from "../assets/img/meo2.png"
import meo3 from "../assets/img/meo3.png"
import meo4 from "../assets/img/meo4.png"
import meo5 from "../assets/img/meo5.png"
import meo6 from "../assets/img/meo6.png"
import meo7 from "../assets/img/meo7.png"
import meo8 from "../assets/img/meo8.png"
import meo9 from "../assets/img/meo9.png"

import articleMiravite from '../data/articleMiravite.json'

function About(themeData) {

  useEffect(() => {
    document.title = 'About • Miravite Consulting Group';
  }, []);

  // Function to convert headline to kebab case
  const toKebabCase = (str) =>
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|[A-Z]*$)|[A-Z]?[a-z]+[0-9]*|[0-9]+/g)
      .map((x) => x.toLowerCase())
      .join('-');

  return (
    <ThemeProvider theme={themeData.theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyles />
      <BackToTopButton />

      <div id="about">
        <section className="about">
          <div className="about-container">
            <span className="about-title">Our Leadership History</span>
            <span className="about-subtitle">
              A Tradition of Excellence Spanning More than Half a Century
            </span>

            <div className="leaders-container">
              <div className="each-leader">
                <div className="img-cont">
                  <img src={feliciano} />
                </div>
                <span className="name">Feliciano F. Miravite</span>
                <span className="suffix">FASP</span>
                <span className="position">Founder</span>
              </div>
              <div className="each-leader">
                <div className="img-cont">
                  <img src={faye} />
                </div>
                <span className="name">Faye S. Miravite</span>
                <span className="suffix">FASP</span>
                <span className="position">Ex-Officio Chairman</span>
              </div>
              <div className="each-leader">
                <div className="img-cont">
                  <img src={romeo} />
                </div>
                <span className="name">Romeo H. Abada</span>
                <span className="suffix">FSA FASP FLMI</span>
                <span className="position">
                  CEO & Senior Consulting Actuary
                </span>
              </div>
            </div>

            {/* <img className='team-photo' src={team} /> */}

            <div className="related-articles-container">
              <span className="related-articles">True Story of Leadership</span>
            </div>

            <div className="leader-story-container">
              <div className="each-leader-story">
                <img src={ffStory} />
                <HashLink 
                smooth to="#founder-story"
                style={{ textDecoration: 'none', color: 'inherit' }} >
                  <div className="story-container">
                    <span className="story-title story-founder">
                      Founder's Story
                    </span>
                    <span className="story-content">
                      It was shortly past midnight. In a battle at Christmas
                      Hill in South Korea, near the 38th Parallel, Eling was
                      about to face the most significant fate that would forever
                      change his life...
                    </span>
                    <span className="story-read-more">Read More</span>
                  </div>
                </HashLink>
              </div>
              <div className="each-leader-story">
                <img src={meoStory} />
                <HashLink 
                smooth to="#fit-for-the-job"
                style={{ textDecoration: 'none', color: 'inherit' }} >
                  <div className="story-container">
                    <span className="story-title">Fit For The Job</span>
                    <span className="story-author">
                      By Romeo Abada, M.E. 1984
                    </span>
                    <span className="story-content">
                      My batch had the misfortune of entering the job market at
                      a time when the Philippine economy was in the doldrums...
                    </span>
                    <span className="story-read-more">Read More</span>
                  </div>
                </HashLink>
              </div>
            </div>

            <span className="about-title">Our Core Values</span>

            <div className="values-container">
              <div className="each-value value1">
                <div className="value-img">
                  <img src={discipline} />
                </div>
                <span className="value-title">Discipline</span>
                <span className="value-content">
                  Discipline to continuously acquire the evolving knowledge and
                  skills that underlie our professional expertise.
                </span>
              </div>
              <div className="each-value value2">
                <div className="value-img">
                  <img src={excellence} />
                </div>
                <span className="value-title">Excellence</span>
                <span className="value-content">
                  Excellence in providing our specialized services.
                </span>
              </div>
              <div className="each-value value3">
                <div className="value-img">
                  <img src={equilibrium} />
                </div>
                <span className="value-title">Equilibrium</span>
                <span className="value-content">
                  Equilibrium in achieving solutions with the perfect fit,
                  within the context of your risk threshold.
                </span>
              </div>
              <div className="each-value value4">
                <div className="value-img">
                  <img src={passiom} />
                </div>
                <span className="value-title">Passion</span>
                <span className="value-content">
                  Passion drives us to be better experts because we are first of
                  all dedicated learners.
                </span>
              </div>
            </div>

            <div className="founder-article">
              <div className="founder-article-banner-container">
                <img id='founder-story' src={founderStory} />
              </div>
              <span className="reader-article-headline">Founder's Story</span>

              <div className="article-body">
                <p>
                  It was shortly past midnight. In a battle at Christmas Hill in
                  South Korea, near the 38th Parallel, Eling was about to face
                  the most significant fate that would forever change his life.
                  Caught amidst the ensuing crossfire, pieces of shrapnel lodged
                  in his spinal column, causing permanent paralysis from the
                  waist down.
                </p>

                <div className="article-body-row">
                  <img src={fm1} />
                  <div className="article-body-p-container-right">
                    <p>
                      Feliciano 'Eling' Fajardo Miravite, FASP (FFM) was born in
                      1927 in Cabatuan, Iloilo City. He graduated from the
                      Philippine Military Academy (PMA) in 1951 and married
                      Marciana 'Mercy' de Leon Santiago a year later. They had
                      an only daughter, Florinda 'Faye' Santiago Miravite, FSA.
                    </p>
                  </div>
                </div>

                <p>
                  Despite the truce, at 12:24 AM on July 15, 1953, land mines
                  exploded in the area occupied by the Battalion and it was at
                  that moment when Eling was hit, becoming a casualty of war and
                  forever losing the use of both legs.
                </p>

                <div className="article-body-row">
                  <div className="article-body-p-container-left">
                    <p>
                      Twelve days later, the Korean war ended but at the age of
                      26, Eling had an even bigger battle to fight — the battle
                      still raging on in his mind — to overcome the devastating
                      sense of hopelessness that comes with adversity of such
                      overwhelming dimensions and to accomplish something even
                      greater in life.
                    </p>
                  </div>
                  <img src={fm2} />
                </div>

                <p>
                  Grateful that the complications of the spinal injury were
                  swiftly and successfully addressed by the excellent medical
                  care — first in Korea, then in Japan and finally at the V Luna
                  Military Hospital in Quezon City — Eling still knew however
                  that the road to rehabilitation was going to be a long
                  process.
                </p>

                <div className="article-body-col">
                  <div className="article-body-p-container-left">
                    <p>
                      Significant aid came from then President Magsaysay who
                      granted Eling the chance to spend a year in a
                      rehabilitation center in Vallejo, California. There, he
                      learned to swim, walk with leg braces and crutches, and
                      drive a car. He acquired mobility that allowed him to
                      function normally and he was on his way.
                    </p>
                  </div>
                  <img src={fm5} />
                </div>

                <p>
                  Returning to the Philippines, Eling accepted a position in the
                  Office of President Magsaysay, as his gesture of gratitude for
                  the financial assistance towards his rehabilitation. After
                  President Magsaysay died in a plane crash, Eling asked himself
                  which profession would allow him to earn a decent living while
                  sitting down, i.e., without having to use his legs. He retired
                  from the Armed Forces of the Philippines (AFP), sold whatever
                  little he owned and with the meager AFP pension, a small loan
                  from his in-laws, and a Fellowship grant, he arrived at the
                  University of Michigan in late 1957, with wife and daughter in
                  tow, to pursue his actuarial studies.
                </p>
                <p>
                  Structural barriers within the university campus — i.e.,
                  twenty steps to reach a building entrance and how to move
                  through the snow in the severe winter climate, among other
                  things - provided challenges to his mobility. Fortunately, the
                  professors provided ramps to facilitate his access and
                  students would push his wheelchair out of snow, mud, or even
                  from an embankment. He received his Master's Degree in
                  Actuarial Mathematics in 1959 and a week later, he was
                  employed as an Actuary with the US Social Security
                  Administration in Washington D.C.
                </p>
                <p>
                  In spite of a Congressional Bill granting him US citizenship,
                  Eling decided to return to the Philippines in 1960. He was the
                  Chief Actuary of the Yuchengco group of insurance companies
                  before eventually deciding to pursue his vision 'to establish
                  the first Actuarial Consulting firm in the Philippines'.
                  Feliciano F. Miravite, Inc. was thus founded and established
                  in 1964 as a company providing actuarial services in the areas
                  of retirement plans for private corporations and insurance
                  consulting. Among its various accomplishments, the company was
                  engaged in 1972 by the Indonesian government to design the
                  Indonesian Social Security System (SSS) and in 1980, the
                  company conducted an independent Actuarial Audit for the
                  Government Service Insurance System.
                </p>
                <p>
                  Eling subsequently obtained his PhD in Business Management
                  from Manuel L. Quezon University.
                </p>

                <div className="article-body-row">
                  <img src={feliciano} />
                  <div className="article-body-p-container-right">
                    <p>
                      During the PMA Alumni Association Homecoming in 1997, his
                      story was described as 'an unwavering determination in
                      overcoming adversity'. Eight years after he had passed
                      away in 2001, his legacy was preserved at the Armed Forces
                      of the Philippines Museum where his personal story is
                      documented alongside Apolinario Mabini, as part of the
                      permanent exhibit entitled 'Giving Tribute to a Disabled
                      Revolutionary Hero and Military Personnel'. This was
                      organized by the Kapisanan ng mga Bayaning Sundalong may
                      Kapansanan sa Pilipinas, Inc. (KBKPI) to showcase the
                      efforts of military men in giving service to their fellow
                      countrymen even after their military service ends and
                      despite incurring physical disabilities.
                    </p>
                  </div>
                </div>

                <p>
                  After Eling passed away in 2001, his daughter Faye took over
                  the company as President & CEO.
                </p>
                <p>
                  Before joining the Company, Faye acquired actuarial consulting
                  experience in the US and briefly in Milan (IT) with the
                  predecessor companies of the present WTW and KPMG. Faye
                  received both her Bachelor's Degree and Master's Degree in
                  Mathematics from the University of Michigan.
                </p>

                <div className="article-body-row">
                  <div className="article-body-p-container-left">
                    <p>
                      Faye complements her actuarial profession with a strong
                      humanities background, having obtained a Dottore in
                      Filosofia (Laurea) degree from the University of Florence
                      (Italy), defending a dissertation on Ontological
                      Hermeneutics entitled 'Il Nesso fra Estetica ed
                      Ermeneutica nel Pensiero di Luigi Pareyson' (1996). She is
                      also enrolled with the Società Italiana di Autori ed
                      Editori (SIAE), i.e., the Italian Society of Authors and
                      Publishers. She has produced three CD albums of her music
                      compositions and has recently published a book 'Foglie di
                      vita', Lucca (2018).
                    </p>
                  </div>
                  <img src={faye} />
                </div>

                <p>
                  Faye continues to cultivate her interests in literature, arts,
                  music, and philosophy, primarily belonging to the period of
                  the late 19th century to early 20th century. It is this
                  humanities perspective that she imprinted on the company, as
                  reflected in the company mission:
                </p>
                <p>
                  'Guided by our core values: Discipline, Equilibrium,
                  Excellence and Passion (D-E-E-P), the Mission of the Miravite
                  Consulting Group (MCG) is to partner with our clients in
                  finding perfect fit solutions towards managing Risk.
                </p>
                <p>
                  Our added-value services derive from our commitment to a
                  continuing learning process in multi-disciplinary fields that
                  complement and reinforce our professional standards and
                  commitment to excellence.'
                </p>
                <p>
                  As CEO, Faye envisioned the Company to be the Partner of
                  Choice for Global Enterprise Risk. Indeed, as she steps down
                  from the CEO position in June 2018, she believes that her
                  successor, Romeo H. Abada, FSA, FASP, FLMI, is the perfect fit
                  to bring forward the legacy of MCG into the future,
                  continuously enhancing and widening the perspective and reach
                  of the Company's professional activities.
                </p>

                <div className="article-body-row">
                  <img src={romeo} />
                  <div className="article-body-p-container-right">
                    <p>
                      With over 50 years of excellence and passion in providing
                      actuarial services, the legacy of MCG — established by the
                      founder, FFM, and carried through by Faye — will now be
                      expanded by Romeo, who is currently CEO-Designate and
                      plans on diversifying the range of services by bringing
                      his expertise in the fields of Retirement and Employee
                      Benefits, Life and Non-Life Insurance, Financial
                      Reporting, Risk Management, and Mergers & Acquisitions.
                    </p>
                  </div>
                </div>

                <p>
                  Romeo has been and continues to be the Market-Thought leader
                  of the DB2DC concept — the conversion of Defined Benefit Plans
                  to Defined Contribution. This leads to de-risking benefits for
                  the Employer if implemented, while increasing and improving
                  employee engagement and retention for the Employee.
                </p>
                <p>
                  As an accredited life, non-life, and HMO actuary by the
                  Insurance Commission of the Philippines, Romeo brings to MCG
                  the expertise and experience acquired from the largest global
                  insurance companies in Indonesia, Vietnam, Philippines, and
                  Hong Kong in various capacities such as Chief Financial
                  Officer, Chief Strategy Officer, and Regional Chief Actuary
                  for Asia Pacific.
                </p>
                <p>
                  On a blank canvas, dots, lines, curves, squares, and circles
                  of different hues start to appear. An abstract work of art is
                  in progress, to become a masterpiece beyond its time. Its
                  thrust is on the future, developing and evolving from the
                  present. Evolution is a never-ending process of learning,
                  development, and adaptation until success is achieved. In MCG,
                  their commitment to continuously learn has rewarded them with
                  over 1,000 satisfied clients in the Philippines and in Asia
                  Pacific, with a growing demand of their services in the
                  evolving market. This evolutionary success is derived not from
                  an equation but from a mission imbedded in each and every one
                  of their employees: continuous learning.
                </p>
                <p>
                  'Logic can take you from A to B but Imagination can take you
                  anywhere, (A. Einstein).'
                </p>
              </div>
            </div>

            <div className='hr'></div>

            <div className="fit-article">

              <div className="fit-article-banner-container">
                <img id='fit-for-the-job' src={fitForJob} />
              </div>
              {/* <span className='article-date'></span> */}
              <span className="reader-article-headline">Fit For The Job</span>
              <span className="article-author">By Romeo Abada, M.E. 1984</span>

              <div className="article-body">
                <div className="article-body-row">
                  <img src={meo1} />
                  <div className="article-body-p-container-right">
                    <p>My batch had the misfortune of entering the job market at a time when the Philippine economy was in the doldrums. Ninoy Aquino had been assassinated a year prior, and the nation was on the cusp of massive change. I, as with the rest of my batchmates, did not have the luxury of job choice. I took what was immediately on offer, an actuarial analyst role at a life insurance company.</p>
                  </div>
                </div>
                <div className="article-body-row">
                  <div className="article-body-p-container-left">
                    <p>Lack of choice notwithstanding, the insurance industry provided the perfect environment to hone the strong Math skills that I knew I possessed. I quickly enrolled for a master's degree in Math at UP, choosing to focus on all insurance-related Math subjects, and eventually embarking on a path to become a Fellow of the Actuarial Society of the Philippines. The motivation was a salary increase each time I passed an exam. In four years, I earned my license. It was early days for this profession, with very few making up this exclusive club. Not content with this local license, I went on to take, one by one, the ten US actuarial licensure exams. Study conditions were far from ideal, as it was during the power crisis of the early 1990s. In six years, I gained the US pedigree. By then, I had moved up and across to Philamlife, the country's leading insurance firm.</p>
                  </div>
                  <img src={meo2} />
                </div>
                <div className="article-body-row">
                  <img src={meo3} />
                  <div className="article-body-p-container-right">
                    <p>Armed with the credentials that were a fitting reward for so many years of hard work and grit, I progressed into an expatriate stint. My first overseas assignment was Indonesia, followed by Vietnam, and Hong Kong. I enjoyed not only the challenges of the job but also the important-sounding titles and the comforts and perks of an expat lifestyle. Actuaries, special as we are, could name our price. This was a far cry from the no-job-choice condition I had first encountered in my career.</p>
                  </div>
                </div>
                <div className="article-body-col">
                  <div className="article-body-p-container-left">
                    <p>I came home in 2009, choosing to cut short that enjoyable expat adventure to spend time with and care for my father. I re-entered the local insurance industry, taking on senior executive roles in consulting firms, Mercer and SGV-EY.</p>
                  </div>
                  <img src={meo4} />
                </div>
                <div className="article-body-row">
                  <img src={meo5} />
                  <div className="article-body-p-container-right">
                    <p>At about this time, I also found myself seeking for a new purpose, new adventures. I joined fun runs and leveled up to half-marathons in 2011. I entered with no desire to push myself to set any personal records, but simply to have fun and to relish the finisher poses (which I made sure was recorded for posterity by hiring/bribing my nephews and nieces to be present at the finish line with their phones and cameras!).</p>
                  </div>
                </div>
                <div className="article-body-col">
                  <div className="article-body-p-container-left">
                    <p>This interest eventually intensified into triathlons (so far, I have done 9), and even Spartan obstacle races, which I successfully hurdled despite being at least 25 years too late. My most recent accomplishment was a podium finish at the Aquathlon — I finished first because I was the only one in my real age group!</p>
                  </div>
                  <img src={meo6} />
                </div>
                <div className="article-body-row">
                  <div className="article-body-p-container-left">
                    <p>At present, I am CEO of Miravite, a consulting company that specializes in actuarial and strategic management services for corporate retirement benefits and life & health insurance products. It is a whole new challenge in itself, running a business with full P&L responsibilities, growing it, and steering a team of 50-plus individuals towards a common vision of being the most respected actuarial firm in the country.</p>
                  </div>
                  <img src={meo7} />
                </div>
                <div className="article-body-row">
                  <img src={meo8} />
                  <div className="article-body-p-container-right">
                    <p>My journey has been a fun and fulfilling one. I am happy with the choices I have made. The outcome of these choices is where I am right now — a healthy and fit CEO confident to claim that I am 'best in class' (ref Aquathlon story ;D). It all goes back to my ME background. I don't think I can lay claim to being one of the best actuaries in the Philippines today if not for that. ME gave me the ability to think strategically, to have breadth as well as depth, to be simultaneously broad-minded and specialized, to have richly-layered insights and solutions. Not just to business problems but to all of life's challenges.</p>
                  </div>
                </div>
                <div className="article-body-row">
                  <div className="article-body-p-container-left">
                    <p>In school, from first grade till university, every class had at least one, that Math whiz who was steps ahead in every flash cards competition in the classroom; who scored more than perfect in exams because bonus challenge questions were easily unlocked for extra points; who was first to solve complicated equations because he or she had no need for pen and paper; and who went out of his or her way to load up each semester with as many Math classes as possible to collect as many A's as possible. I know that in my Management Engineering batch of 1984, as perhaps with all batches of this elite program known to attract the numerically-inclined brains, we had more than one. But Romeo Abada, Meo as we call him, easily shone as among the brightest bulbs in a brightly lit room.</p>
                  </div>
                  <img src={meo9} />
                </div>
                
              </div>
            </div>

          </div>
        </section>
      </div>
    </ThemeProvider>
  );
};

export default About;