import React, { useState, useRef, useEffect } from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { useHistory } from "react-router-dom";
import './styles/terms.scss'

function Terms(themeData) {

  useEffect(() => {
    document.title = 'Terms & Conditions • Jins Gonzales Design';
  }, []);

    const history = useHistory();
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='terms'>

          <section className="terms section-top section-bottom">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span className="title">Terms & Conditions</span>
                </div>
            </div>
            <div className="wrapper">
              <div className='terms-content'>
                <div className='terms-title'>Acceptance of Terms</div>
                <br/>
                <div className='terms-info'>By accessing or using the services provided by Miravite Consulting Group (referred to as "we," "us," or "our"), you agree to be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please refrain from using our services.</div>
                <br/>
                <div className='terms-title'>Scope of Services</div>
                <br/>
                

              </div>
            </div>
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default Terms;