import { createGlobalStyle } from 'styled-components'
import styled, { keyframes } from 'styled-components'

export const lightTheme = {
    hr: '0.5px solid rgba(0,0,0,0.09)',
    hr2: '1px solid rgba(0,0,0,0.09)',
    vl: '1px solid #F5F5F5',
    fill: '#F5F5F5',
    dash: '3px dashed white;',
    footer: '#F5F5F5 !important',
    info: "#c2c2c2 !important",
    mainBg: '#ffffff !important',
    mainBgReverse: '#363636 !important',
    mainTextFooter: '#696969 !important',
    mainText: '#363636 !important',
    mainTextRevert: '#F5F5F5 !important',
    mainBorder: '1px solid #696969',
    navShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
    neuShadow: '-1px -2px 1px rgba(255, 255, 255, 1), 1px 1px 3px rgba(0, 0, 0, 0.15) !important',
    neuShadowActive: 'inset -1px -2px 1px rgba(255, 255, 255, 1), inset 1px 1px 3px rgba(0, 0, 0, 0.15) !important'
}

export const darkTheme = {
    hr: '0.5px solid rgba(255, 255, 255, 0.09)',
    hr2: '1px solid rgba(255, 255, 255, 0.09)',
    vl: '1px solid #363636',
    fill: '#363636',
    dash: '3px dashed #363636;',
    footer: '#151515 !important',
    info: "#535353 !important",
    mainBg: '#000000 !important',
    mainBgReverse: '#F5F5F5 !important',
    mainTextFooter: '#a7a7a7 !important',
    mainText: '#F5F5F5 !important',
    mainRevert: '#363636 !important',
    mainBorder: '1px solid #a7a7a7',
    navShadow: '1px 1px 3px rgba(255, 255, 255, 0.2)',
    neuShadow: '-1px -2px 1px rgba(255, 255, 255, 0.15), 1px 1px 3px rgba(0, 0, 0, 0.50) !important',
    neuShadowActive: 'inset -1px -2px 1px rgba(255, 255, 255, 0.15), inset 1px 1px 3px rgba(0, 0, 0, 0.50) !important'
}

let fill = true;

const fillAnimation = (props) => {
        return keyframes`
        from{
            fill: transparent;
        }
        to{
            fill: ${props}
        }
    `
}

const fillAnimationDelay = () => {
    if(fill === true) {
        setTimeout(function(){ fill = false; }, 3500);
        return `
        0.5s ease forwards 4.5s 
    `
    } else {
        return `
        0s ease forwards 0s 
    `
    }
}

const strokeStyle = (props) => {
    if(fill === true) {
        return props
    }
    else {
        // return `
        //     transparent
        // `
        return props
    }
}

export const GlobalStyles = createGlobalStyle`

    body {
        color: ${(props) => props.theme.mainText};
    }
    nav {
        background-color: ${(props) => props.theme.mainBg};
        box-shadow: ${(props) => props.theme.navShadow};
    }
    section {
        background-color: ${(props) => props.theme.mainBg}
    }
    section .subtitle path,span {
        fill: ${(props) => props.theme.mainText};
    }
    .logo svg path {
      fill: ${(props) => props.theme.mainBg};
    }
    .footer {
        background-color: ${(props) => props.theme.footer};
        color: ${(props) => props.theme.mainTextFooter};
    }
    .footer a{
        color: ${(props) => props.theme.mainTextFooter};
    }
    .footer svg path {
      fill: ${(props) => props.theme.mainTextFooter};
    }
    @media screen and (max-width: 480px) {
      .soc-medLink, .site-mapLink {
        border: ${(props) => props.theme.mainBorder};
      }
    }

    hr {
        border: ${(props) => props.theme.hr}
    }


    .link-name {
        background-color: ${(props) => props.theme.mainBg};
        // box-shadow : ${(props) => props.theme.neuShadow}
    }
    .active-route {
        // box-shadow : ${(props) => props.theme.neuShadowActive}
    }
    .link-name:active {
        // box-shadow : ${(props) => props.theme.neuShadowActive}
    }

    .neu {
        // box-shadow : ${(props) => props.theme.neuShadow}
    }
    .mode:active {
        // box-shadow : ${(props) => props.theme.neuShadowActive}
    }

    .backdrop {
        background-color: ${(props) => props.theme.mainBg}
    }
    .menu-open {
        background-color: ${(props) => props.theme.mainBg}
    }
    .menu-top-in:active, .menu-bot-in:active {
        box-shadow : ${(props) => props.theme.neuShadowActive}
    }

    #home {
        background-color: ${(props) => props.theme.mainBg}
    }
    .parent .widget {
        background-color: ${(props) => props.theme.footer}
    }
    .inner-contact {
        background-color: ${(props) => props.theme.footer}
    }

    .privacy-info {
      color: ${(props) => props.theme.mainTextFooter};
    }
    strong {
      color: ${(props) => props.theme.mainText};
    }

    .terms-info {
      color: ${(props) => props.theme.mainTextFooter};
    }



`