import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { useHistory } from 'react-router-dom';
import '../special-pages/styles/founder-story.scss'
import BackToTopButton from '../component/back-to-top.js';

import { useParams } from 'react-router-dom';
import founderStory from '../assets/img/founders-story.png'
import fm1 from "../assets/img/fm1.jpg"
import fm2 from "../assets/img/fm2.jpg"
import fm3 from "../assets/img/fm3.jpg"
import fm4 from "../assets/img/fm4.jpg"
import fm5 from "../assets/img/fm5.jpg"
import faye from "../assets/img/faye.png"
import romeo from "../assets/img/romeo.png"
import feliciano from "../assets/img/feliciano.png"


function FoundersStory(themeData) {

  useEffect(() => {
    document.title = `Founder's Story • Miravite Consulting Group`;
  }, []);

  // function to navigate to path
  const history = useHistory();


  return (
    <ThemeProvider theme={themeData.theme === "light" ? lightTheme : darkTheme}>
    <GlobalStyles />
    <BackToTopButton />


      <div id="founder">
        <section className="founder">
          <div className="founder-container">

            <div className='article-top-margin'></div>

            <div className="founder-article">

              <div className='back-button' onClick={() => history.goBack()}>
                <button className="button">
                  <span>Go Back</span>
                  <span className='back-arrow'>
                    <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                    </svg>
                  </span>
                </button>
              </div>

              <div className='founder-article-banner-container'>
                <img src={founderStory} />
              </div>
              <span className='reader-article-headline'>Founder's Story</span>

              <div className="article-body">
                <p>It was shortly past midnight. In a battle at Christmas Hill in South Korea, near the 38th Parallel, Eling was about to face the most significant fate that would forever change his life. Caught amidst the ensuing crossfire, pieces of shrapnel lodged in his spinal column, causing permanent paralysis from the waist down.</p>

                <div className="article-body-row">
                  <img src={fm1} />
                  <div className="article-body-p-container-right">
                    <p>Feliciano 'Eling' Fajardo Miravite, FASP (FFM) was born in 1927 in Cabatuan, Iloilo City. He graduated from the Philippine Military Academy (PMA) in 1951 and married Marciana 'Mercy' de Leon Santiago a year later. They had an only daughter, Florinda 'Faye' Santiago Miravite, FSA.</p>
                  </div>
                </div>

                <p>Despite the truce, at 12:24 AM on July 15, 1953, land mines exploded in the area occupied by the Battalion and it was at that moment when Eling was hit, becoming a casualty of war and forever losing the use of both legs.</p>

                <div className="article-body-row">
                  <div className="article-body-p-container-left">
                    <p>Twelve days later, the Korean war ended but at the age of 26, Eling had an even bigger battle to fight — the battle still raging on in his mind — to overcome the devastating sense of hopelessness that comes with adversity of such overwhelming dimensions and to accomplish something even greater in life.</p>
                  </div>
                  <img src={fm2} />
                </div>

                <p>Grateful that the complications of the spinal injury were swiftly and successfully addressed by the excellent medical care — first in Korea, then in Japan and finally at the V Luna Military Hospital in Quezon City — Eling still knew however that the road to rehabilitation was going to be a long process.</p>

                <div className="article-body-col">
                  <div className="article-body-p-container-left">
                    <p>Significant aid came from then President Magsaysay who granted Eling the chance to spend a year in a rehabilitation center in Vallejo, California. There, he learned to swim, walk with leg braces and crutches, and drive a car. He acquired mobility that allowed him to function normally and he was on his way.</p>
                  </div>
                  <img src={fm5} />
                </div>

                <p>Returning to the Philippines, Eling accepted a position in the Office of President Magsaysay, as his gesture of gratitude for the financial assistance towards his rehabilitation. After President Magsaysay died in a plane crash, Eling asked himself which profession would allow him to earn a decent living while sitting down, i.e., without having to use his legs. He retired from the Armed Forces of the Philippines (AFP), sold whatever little he owned and with the meager AFP pension, a small loan from his in-laws, and a Fellowship grant, he arrived at the University of Michigan in late 1957, with wife and daughter in tow, to pursue his actuarial studies.</p>
                <p>Structural barriers within the university campus — i.e., twenty steps to reach a building entrance and how to move through the snow in the severe winter climate, among other things - provided challenges to his mobility. Fortunately, the professors provided ramps to facilitate his access and students would push his wheelchair out of snow, mud, or even from an embankment. He received his Master's Degree in Actuarial Mathematics in 1959 and a week later, he was employed as an Actuary with the US Social Security Administration in Washington D.C.</p>
                <p>In spite of a Congressional Bill granting him US citizenship, Eling decided to return to the Philippines in 1960. He was the Chief Actuary of the Yuchengco group of insurance companies before eventually deciding to pursue his vision 'to establish the first Actuarial Consulting firm in the Philippines'. Feliciano F. Miravite, Inc. was thus founded and established in 1964 as a company providing actuarial services in the areas of retirement plans for private corporations and insurance consulting. Among its various accomplishments, the company was engaged in 1972 by the Indonesian government to design the Indonesian Social Security System (SSS) and in 1980, the company conducted an independent Actuarial Audit for the Government Service Insurance System.</p>
                <p>Eling subsequently obtained his PhD in Business Management from Manuel L. Quezon University.</p>

                <div className="article-body-row">
                  <img src={feliciano} />
                  <div className="article-body-p-container-right">
                    <p>During the PMA Alumni Association Homecoming in 1997, his story was described as 'an unwavering determination in overcoming adversity'. Eight years after he had passed away in 2001, his legacy was preserved at the Armed Forces of the Philippines Museum where his personal story is documented alongside Apolinario Mabini, as part of the permanent exhibit entitled 'Giving Tribute to a Disabled Revolutionary Hero and Military Personnel'. This was organized by the Kapisanan ng mga Bayaning Sundalong may Kapansanan sa Pilipinas, Inc. (KBKPI) to showcase the efforts of military men in giving service to their fellow countrymen even after their military service ends and despite incurring physical disabilities.</p>
                  </div>
                </div>

                <p>After Eling passed away in 2001, his daughter Faye took over the company as President & CEO.</p>
                <p>Before joining the Company, Faye acquired actuarial consulting experience in the US and briefly in Milan (IT) with the predecessor companies of the present WTW and KPMG. Faye received both her Bachelor's Degree and Master's Degree in Mathematics from the University of Michigan.</p>

                <div className="article-body-row">
                  <div className="article-body-p-container-left">
                    <p>Faye complements her actuarial profession with a strong humanities background, having obtained a Dottore in Filosofia (Laurea) degree from the University of Florence (Italy), defending a dissertation on Ontological Hermeneutics entitled 'Il Nesso fra Estetica ed Ermeneutica nel Pensiero di Luigi Pareyson' (1996). She is also enrolled with the Società Italiana di Autori ed Editori (SIAE), i.e., the Italian Society of Authors and Publishers. She has produced three CD albums of her music compositions and has recently published a book 'Foglie di vita', Lucca (2018).</p>
                  </div>
                  <img src={faye} />
                </div>

                <p>Faye continues to cultivate her interests in literature, arts, music, and philosophy, primarily belonging to the period of the late 19th century to early 20th century. It is this humanities perspective that she imprinted on the company, as reflected in the company mission:</p>
                <p>'Guided by our core values: Discipline, Equilibrium, Excellence and Passion (D-E-E-P), the Mission of the Miravite Consulting Group (MCG) is to partner with our clients in finding perfect fit solutions towards managing Risk.</p>
                <p>Our added-value services derive from our commitment to a continuing learning process in multi-disciplinary fields that complement and reinforce our professional standards and commitment to excellence.'</p>
                <p>As CEO, Faye envisioned the Company to be the Partner of Choice for Global Enterprise Risk. Indeed, as she steps down from the CEO position in June 2018, she believes that her successor, Romeo H. Abada, FSA, FASP, FLMI, is the perfect fit to bring forward the legacy of MCG into the future, continuously enhancing and widening the perspective and reach of the Company's professional activities.</p>

                <div className="article-body-row">
                  <img src={romeo} />
                  <div className="article-body-p-container-right">
                    <p>With over 50 years of excellence and passion in providing actuarial services, the legacy of MCG — established by the founder, FFM, and carried through by Faye — will now be expanded by Romeo, who is currently CEO-Designate and plans on diversifying the range of services by bringing his expertise in the fields of Retirement and Employee Benefits, Life and Non-Life Insurance, Financial Reporting, Risk Management, and Mergers & Acquisitions.</p>
                  </div>
                </div>

                <p>Romeo has been and continues to be the Market-Thought leader of the DB2DC concept — the conversion of Defined Benefit Plans to Defined Contribution. This leads to de-risking benefits for the Employer if implemented, while increasing and improving employee engagement and retention for the Employee.</p>
                <p>As an accredited life, non-life, and HMO actuary by the Insurance Commission of the Philippines, Romeo brings to MCG the expertise and experience acquired from the largest global insurance companies in Indonesia, Vietnam, Philippines, and Hong Kong in various capacities such as Chief Financial Officer, Chief Strategy Officer, and Regional Chief Actuary for Asia Pacific.</p>
                <p>On a blank canvas, dots, lines, curves, squares, and circles of different hues start to appear. An abstract work of art is in progress, to become a masterpiece beyond its time. Its thrust is on the future, developing and evolving from the present. Evolution is a never-ending process of learning, development, and adaptation until success is achieved. In MCG, their commitment to continuously learn has rewarded them with over 1,000 satisfied clients in the Philippines and in Asia Pacific, with a growing demand of their services in the evolving market. This evolutionary success is derived not from an equation but from a mission imbedded in each and every one of their employees: continuous learning.</p>
                <p>'Logic can take you from A to B but Imagination can take you anywhere, (A. Einstein).'</p>

                
              </div>
            </div>
          </div>
        </section>
      </div>
    </ThemeProvider>
  );
};

export default FoundersStory;