import React, { useState, useRef, useEffect } from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { useHistory } from "react-router-dom";
import './styles/privacy.scss'

function Privacy(themeData) {

  useEffect(() => {
    document.title = 'Privacy Policy • Miravite Consulting Group';
  }, []);

    const history = useHistory();
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='privacy'>

          <section className="privacy section-top section-bottom">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    {/* <span className="privacy-title">Privacy Policy</span> */}
                </div>
            </div>
            <div className="wrapper">
              <div className='privacy-content'>

                <span className="privacy-title">Privacy Policy</span>
                <h1>Data Privacy Statement</h1>
                <p>This page tells you everything you need to know about how FFMiravite, Inc. Doing Business under the name and style of Miravite Consulting Group protects your data and your rights in relation to your personal information.</p>
                <p>If you have any questions concerning data privacy or if you have comments about what permissions our company uses in terms of data collection, processing, transmission, storage and disposal, please contact us at info@MAI-Asia.com or visit us at www.ffmiravite.com.</p>
                <p>Any changes that we may make in the future on how Miravite Consulting Group protects your personal information or on any related statements herein, will be posted on this page and on this document.</p>
                <p>This privacy statement is effective on January 1, 2018.</p>
                
                <h1>How Miravite Consulting Group Protects Your Personal Information</h1>
                <p>Miravite Consulting Group protects your personal information through the Miravite Consulting Group Data Privacy Policy and other Non-disclosure policies that cover the collection, processing, use, transmission, storage and disposal of data. Together, these policies ensure that appropriate data privacy safeguards are in place in all activities across our organization. Such policies reflect the standards contained in the Philippine Data Privacy Act.</p>

                <h1>Miravite Consulting Group’s Data Privacy Policy</h1>
                <p>The following section summarizes Miravite Consulting Group’s Data Privacy Policy.</p>
                <ul>
                  <li>What personal information do we collect?</li>
                  <li>Why personal information will be collected?</li>
                  <li>When do we collect personal information?</li>
                  <li>Will we share your personal information with outside parties?</li>
                  <li>What about sensitive information?</li>
                  <li>What about data security?</li>
                  <li>Where will your personal data be processed?</li>
                  <li>Retention of your personal information?</li>
                  <li>Data Subject rights</li>
                  <li>Questions and complaints.</li>
                </ul>

                <h1>What personal information do we collect?</h1>
                <p>Miravite Consulting Group will collect information which may include personal information of our employees, clients, applicant-candidates, suppliers, business contacts, shareholders and website users. We will always, to the best of our ability, give individuals appropriate notice of what information will be collected and how it will be used. We will only process personal information if the data subject is informed and the process is compatible with a declared and specific purpose.</p>

                <h1>Why personal information will be collected?</h1>
                <p>The Miravite Consulting Group may use the information that it collects, including any personal information, for the following reasons:</p>
                <ul>
                  <li>To improve the website</li>
                  <li>To improve customer service</li>
                  <li>To administer promotions, surveys or other site features</li>
                  <li>To process business transactions</li>
                  <li>To ask for ratings and reviews of services</li>
                  <li>To follow up with you after correspondence (live chat, email, SMS or phone inquiries)</li>
                  <li>To send periodic email for various purposes</li>
                  <li>To bill you for our services</li>
                </ul>

                <h1>When do we collect personal information?</h1>
                <p>The Miravite Consulting Group collects information, including personal information:</p>
                <ul>
                  <li>When registering on our website</li>
                  <li>When availing of our services</li>
                  <li>For Business proposals</li>
                  <li>For Marketing promotions or support materials</li>
                  <li>Upon subscribing to our newsletter</li>
                  <li>When we conduct a survey(s)</li>
                  <li>When filling out a form</li>
                  <li>When using our Live Chat</li>
                  <li>When providing us with feedback on our services</li>
                </ul>

                <h1>Will we share your personal information with outside parties?</h1>
                <p>We may transfer personal information to our contracted service providers and advisors who may be located within the Philippines or in other countries. But before we do so, we take steps to ensure that your personal information will be given adequate protection as required by relevant data protection laws and the Miravite Consulting Group’s internal policies.</p>
                <p>However, there will be other times when we need to share or disclose personal data you provide for a specific purpose, for example:</p>
                <ul>
                  <li>In the case we are running a joint event and have informed you that the information will be shared with our partners. But again, we will notify you as to why we are asking for specific information and it is completely your decision whether or not to provide it;</li>
                  <li>We may need to disclose your personal information to third parties in the event that we sell or liquidate any part of our business or assets;</li>
                  <li>We will disclose or share your personal data to comply with any legal obligation, or to protect the rights, property, or safety of Miravite Consulting Group, our clients, or others.</li>
                </ul>

                <h1>What about sensitive personal data?</h1>
                <p>We do not generally seek to collect sensitive personal information (also known as special categories) through our website or other sources. In the limited cases where we do seek to collect such data, we will do this in accordance with the Philippine data privacy law requirements. If you choose to provide us with unsolicited sensitive personal data, you consent to our using the data, subject to applicable law as described in this privacy statement. The term "sensitive information" refers to the various categories of personal data identified by Philippine Data Privacy Law as requiring special treatment, including in some circumstances the need to obtain explicit consent. These categories may include personal identity numbers, financial account information, racial or ethnic origin, political opinions, religious, philosophical or other similar beliefs, membership of a trade union or profession or trade association, physical or mental health, biometric or genetic data, sexual life, or criminal record (including information about suspected criminal activities).</p>

                <h1>What about data security?</h1>
                <p>We take appropriate steps to maintain the security of the personal information collected. Regarding your use of our websites, portals or other facilities, you should understand that the open nature of the Internet is such that information and personal data may flow over networks connecting you to our systems without security measures and may be accessed and used by people other than those for whom the data is intended.</p>
                <p>Our facilities, may, from time to time, contain links to and from the sites of our partner networks and affiliates. If you follow a link to any of these sites, please note that these sites have their own privacy policies and that we do not accept any responsibility or liability for these policies or sites. Please check these policies before you submit any personal data to these sites.</p>
                
                <h1>Where will your personal data be processed?</h1>
                <p>As a local Philippine organization with local Information Technology systems, the personal data we collect is routed, stored, or transferred locally throughout the Miravite Consulting Group’s organization in accordance with our internal privacy policies and to our vendors in accordance with Data Privacy Act of 2012.</p>
                
                <h1>Data Subject Rights</h1>
                <p>What are your rights as Data Subjects: you are entitled to know whether we hold any of your personal information and, if we do, you have access to that personal information and can require it to be corrected if it is inaccurate. We generally do not make decisions by purely automatic means, but if for some reason we must, you have the right to object.</p>
                <p>Contact us to exercise any of your rights. Below is a list of your basic rights, as follows:</p>
                <ul>
                  <li>To make complaints, to obtain appropriate redress and, where appropriate, receive compensation from the Miravite Consulting Group, which originally obtained and processed your personal information, in respect of any damage suffered by you as a result of a breach of the Data Privacy Policy by Miravite Consulting Group to which the personal information may have been transferred without regard to the intended scope of our specific purposes and activities;</li>
                  <li>To lodge a complaint with the National Privacy Commission and/ or to take action through a court to enforce compliance by Miravite Consulting Group with the terms of the Data Privacy Policy.</li>
                </ul>

                <h1>Retention of Your Data</h1>
                <p>The Miravite Consulting Group will retain your personal information in our data center only for as long as necessary. If you have an account with us, we will only keep the data while your account is active or for as long as needed to provide services to you and, as further needed for us to comply with our local legal and contractual obligations. However, such information can only be accessed by those authorized, i.e., Personal Information Controller or Personal Information Processor with special access rights. If you wish to cancel your account, or request that we no longer use your personal information to provide you services, contact us via the applicable information below.</p>

                <h1>You Have a Question or Complaint</h1>
                <p>If you cannot find what you are looking for here, or you have a concern about our use of your personal information, please email us at info@MAI-Asia.com or contact us at (02) 8856 7118.</p>
                
              </div>
            </div>
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default Privacy;