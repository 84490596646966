import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import './styles/news.scss'

function News(themeData) {

  useEffect(() => {
    document.title = 'News • Miravite Consulting Group';
  }, []);

  return (

    <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
    <GlobalStyles />

      <div id='news'>
        <section className="projects section-top section-bottom">
          <div className='pagetopMargin'></div>
          <div className="wrapper">
                <div className="flex flex-column">
                    <span className="title">News</span>
                </div>
            </div>

          <div className="wrapper">
            
          </div>
        </section>
      </div>
    </ThemeProvider>
  );
};

export default News;