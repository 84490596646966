// BackToTopButton.js
import React, { useState, useEffect } from 'react';

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show or hide the button depending on the scroll position
  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll the page back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <button className='back-to-top-btn'
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            bottom: '24px',
            right: '24px',
            fontSize: '18px',
            borderRadius: '50%',
            backgroundColor: '#089CAA',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            height: '35px',
            width: '35px',
            zIndex: 1000,

          }}
        >
          <span className=''>
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.99999 3.7999L2.09999 6.6999C1.91665 6.88324 1.68332 6.9749 1.39999 6.9749C1.11665 6.9749 0.883321 6.88324 0.699988 6.6999C0.516654 6.51657 0.424988 6.28324 0.424988 5.9999C0.424988 5.71657 0.516654 5.48324 0.699988 5.2999L5.29999 0.699902C5.49999 0.499902 5.73332 0.399902 5.99999 0.399902C6.26665 0.399902 6.49999 0.499902 6.69999 0.699902L11.3 5.2999C11.4833 5.48324 11.575 5.71657 11.575 5.9999C11.575 6.28324 11.4833 6.51657 11.3 6.6999C11.1167 6.88324 10.8833 6.9749 10.6 6.9749C10.3167 6.9749 10.0833 6.88324 9.89999 6.6999L6.99999 3.7999V11.9999C6.99999 12.2832 6.90415 12.5207 6.71249 12.7124C6.52082 12.9041 6.28332 12.9999 5.99999 12.9999C5.71665 12.9999 5.47915 12.9041 5.28749 12.7124C5.09582 12.5207 4.99999 12.2832 4.99999 11.9999V3.7999Z" fill="white"/>
            </svg>
          </span>
          

        </button>
      )}
    </>
  );
};

export default BackToTopButton;