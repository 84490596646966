import React, { useState, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import './styles/careers.scss'
import BackToTopButton from '../component/back-to-top.js';

import lobBenefits from '../assets/img/lob-emp.png'
import why2 from '../assets/img/why2.png'
import why3 from '../assets/img/why3.png'
import path1 from '../assets/img/path-01.png'
import path2 from '../assets/img/path-02.png'
import learning1 from '../assets/img/learning-01.png'
import learning2 from '../assets/img/learning-02.png'
import balance1 from '../assets/img/balance-01.png'
import balance2 from '../assets/img/balance-02.png'

function Careers(themeData) {

  useEffect(() => {
    document.title = 'Careers • Miravite Consulting Group';
  }, []);

  return (

    <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
    <GlobalStyles />
    <BackToTopButton />


      <div id='careers'>

        <section className="careers">
            <div className='careers-container'>
              <span className='careers-title'>Careers</span>
              <span className='careers-subtitle'>Join our growing team of top-notch consultants.</span>

              {/* <span className='qq'>Why Miravite?</span> */}

              <TypeAnimation className='qq'
                sequence={[
                  'Why Miravite?',
                  10000,
                  '',
                  500
                ]}
                repeat={Infinity}
              />

              <div className='why-container'>
                <div className='each-why-card why1'>
                  <div className='ret-img'>
                    <img src={path1} />
                    <img src={path2} />
                  </div>
                  <span className='ret-title'>Team of Highly Skilled & Driven Consultants</span>
                  <span className='ret-desc'>Work with the best Actuarial, Finance & Retirement Consultants led by the Miravite Actuary. </span>
                </div>
                <div className='each-why-card why2'>
                  <div className='ret-img'>
                    <img src={learning1} />
                    <img src={learning2} />
                  </div>
                  <span className='ret-title'>Diverse Learnings in Actuarial & Financial Fields</span>
                  <span className='ret-desc'>Experience projects across the different sectors of the insurance industry and the retirement space. </span>
                </div>
                <div className='each-why-card why3'>
                  <div className='ret-img'>
                    <img src={balance1} />
                    <img src={balance2} />
                  </div>
                  <span className='ret-title'>Holistic Training in Technical & Soft Skills</span>
                  <span className='ret-desc'>Develop subject-matter expertise, executive communications, and project management capabilities. </span>
                </div>
              </div>

              <span className='careers-title'>We are always open to hire the best people.</span>

              <div className='position-container'>
                {/* <div className='each-position-card'>
                  <div className='inner-position'>
                    <div>
                      <span className='ret-title'>Web Developer</span>
                      <p>Candidate must possess at least a Bachelor's/College Degree in Computer Science or equivalent.</p>
                      <p>Required Skills:</p>
                      <ul>
                        <li>Angular / React / NodeJS / MySQL</li>
                        <li>PHP / Code Igniter / Laravel</li>
                      </ul>
                      <p>Preferably 1-2 Years Specializing in IT/Computer - Software Development or equivalent.</p>
                      <p>Willing to work in Bonifacio Global City, Taguig</p>
                    </div>
                    <div className='about-button'>
                    <a href="mailto:info@mai-asia.com?subject=Applying%20for%20the%20Position%20of%20Web%20Developer&body=PLEASE%20ATTACH%20YOUR%20RESUME">
                      <button className="button">
                        <span>Apply Now</span>
                        <span>
                          <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                          </svg>
                        </span>
                      </button>
                    </a>
                    </div>
                  </div>
                </div>
                <div className='each-position-card'>
                  <div className='inner-position'>
                    <div>
                      <span className='ret-title'>Accounts Receivable Analyst</span>
                      <p>Candidate must possess at least  a Bachelor’s/College Degree in Accountancy</p>
                      <p>At least 1 year experience in Credit, Collection and Accounts Receivable</p>
                      <p>Required Skills:</p>
                      <ul>
                        <li>Must have good communication skills</li>
                        <li>Must be knowledgeable in Excel and Accounting System (e.g., QuickBooks)</li>
                      </ul>
                      <p>Willing to work in Bonifacio Global City, Taguig</p>
                    </div>
                    <div className='about-button'>
                    <a href="mailto:info@mai-asia.com?subject=Applying%20for%20the%20Position%20of%20Accounts%20Receivable%20Analyst&body=PLEASE%20ATTACH%20YOUR%20RESUME">
                      <button className="button">
                        <span>Apply Now</span>
                        <span>
                          <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                          </svg>
                        </span>
                      </button>
                    </a>
                    </div>
                  </div>
                </div>
                <div className='each-position-card'>
                  <div className='inner-position'>
                    <div>
                    <span className='ret-title'>Actuarial Analyst</span>
                      <p>Candidate must possess at least Bachelor's/College Degree in Mathematics or equivalent.</p>
                      <p>At least passed one (1) actuarial exam is an advantage.</p>
                      <p>Required Skills:</p>
                      <ul>
                        <li>Analytical and Technical Skills</li>
                        <li>Computer Literate</li>
                        <li>Problem Solving Skills</li>
                      </ul>
                      <p>Preferably 1 to 4 year experience specializing in Actuarial Science/Statistics or equivalent.</p>
                      <p>Willing to work in Bonifacio Global City, Taguig</p>
                    </div>
                    <div className='about-button'>
                    <a href="mailto:info@mai-asia.com?subject=Applying%20for%20the%20Position%20of%20Actuarial%20Analyst&body=PLEASE%20ATTACH%20YOUR%20RESUME">
                      <button className="button">
                        <span>Apply Now</span>
                        <span>
                          <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                          </svg>
                        </span>
                      </button>
                    </a>
                    </div>
                  </div>
                </div> */}

                <div className='each-position-card'>
                  <div className='inner-position'>
                    <div>
                      <span className='ret-title'>Actuarial Manager | Senior Actuarial Analyst | Actuarial Analyst</span>
                    </div>
                    <div className='about-button'>
                    <a href="mailto:info@mai-asia.com?subject=Applying%20for%20the%20Position%20of&body=PLEASE%20ATTACH%20YOUR%20RESUME">
                      <button className="button">
                        <span>Apply Now</span>
                        <span>
                          <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                          </svg>
                        </span>
                      </button>
                    </a>
                    </div>
                  </div>
                </div>
                <div className='each-position-card'>
                  <div className='inner-position'>
                    <div>
                      <span className='ret-title'>Accounting Manager | Finance Supervisor | Accounts Receivable Analyst</span>
                    </div>
                    <div className='about-button'>
                    <a href="mailto:info@mai-asia.com?subject=Applying%20for%20the%20Position%20of&body=PLEASE%20ATTACH%20YOUR%20RESUME">
                      <button className="button">
                        <span>Apply Now</span>
                        <span>
                          <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                          </svg>
                        </span>
                      </button>
                    </a>
                    </div>
                  </div>
                </div>
                <div className='each-position-card'>
                  <div className='inner-position'>
                    <div>
                      <span className='ret-title'>Web Developer</span>
                    </div>
                    <div className='about-button'>
                    <a href="mailto:info@mai-asia.com?subject=Applying%20for%20the%20Position%20of&body=PLEASE%20ATTACH%20YOUR%20RESUME">
                      <button className="button">
                        <span>Apply Now</span>
                        <span>
                          <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                          </svg>
                        </span>
                      </button>
                    </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </section>
      </div>
    </ThemeProvider>
  );
};

export default Careers;