import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { useHistory } from 'react-router-dom';
import './styles/read.scss'
import BackToTopButton from '../component/back-to-top.js';

import { useParams } from 'react-router-dom';
import articlePension from '../data/articlePension.json';
import articleBenefits from '../data/articleBenefits.json';
import articleInsurance from '../data/articleInsurance.json';
import articleMiravite from '../data/articleMiravite.json';



function Read(themeData) {

  const history = useHistory();
  const { source, headline } = useParams();

  // Function to convert headline to kebab case
  const toKebabCase = (str) =>
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|[A-Z]*$)|[A-Z]?[a-z]+[0-9]*|[0-9]+/g)
      .map((x) => x.toLowerCase())
      .join('-');

  // Determine the data source based on the 'source' parameter
  let data = [];
  switch (source) {
    case 'pension-article':
      data = articlePension;
      break;
    case 'benefits-article':
      data = articleBenefits;
      break;
    case 'insurance-article':
      data = articleInsurance;
      break;
    case 'miravite-article':
      data = articleMiravite;
      break;
    default:
      data = null;
  }

  // Find the article matching the headline in the URL
  const article = data ? data.find(
    (item) => toKebabCase(item.headline) === headline
  ) : null;

  useEffect(() => {
    if (article) {
      document.title = `${article.headline} • Miravite Consulting Group`;
    } else {
      document.title = 'Read • Miravite Consulting Group';
    }
  }, [article]);

  if (!data) {
    return <h2>Invalid data source</h2>;
  }

  if (!article) {
    return <h2>Article not found</h2>;
  }

  return (
    <ThemeProvider theme={themeData.theme === "light" ? lightTheme : darkTheme}>
    <GlobalStyles />
    <BackToTopButton />

      <div id="read">
        <section className="read">
          <div className="read-container">
            {/* <span className="read-title">Read</span> */}

            <div className='article-top-margin'></div>

            <div className="read-article">

              <div className='back-button' onClick={() => history.goBack()}>
                <button className="button">
                  <span>Go Back</span>
                  <span className='back-arrow'>
                    <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                    </svg>
                  </span>
                </button>
              </div>

              <div className='read-article-banner-container'>
                <img referrerPolicy="no-referrer" src={`/img/assets/${article.img}`} />
              </div>
              <span className='article-date'>{article.date}</span>
              <span className='reader-article-headline'>{article.headline}</span>
              {/* <p>{article.date}</p> */}
              <span className='article-author'>{article.author}</span>
              {/* <p className='article-body'>{article.body}</p> */}
              {article.body.map((paragraph, index) => (
                <p className='article-body' key={index}>{paragraph}</p>
              ))}
            </div>
          </div>
        </section>
      </div>
    </ThemeProvider>
  );
};

export default Read;