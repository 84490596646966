import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyClDrQTDjn4k-wWsJlV3lBuSzwUKx3-xVQ",
    authDomain: "miravite.firebaseapp.com",
    projectId: "miravite",
    storageBucket: "miravite.appspot.com",
    messagingSenderId: "774351414097",
    appId: "1:774351414097:web:f67a1bd4e1b36c9af12970",
    measurementId: "G-5KCB2MKZ31"
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { firebase, firestore, storage, auth, provider };