import React, { useState, useRef, useEffect } from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import './styles/services.scss'

function Services(themeData) {

  useEffect(() => {
    document.title = 'Services • Miravite Consulting Group';
  }, []);
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <div id='services'>

          <section className="services section-top section-bottom">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span className="title">Services</span>
                </div>
            </div>

            <div className='wrapper'>
              <div className='type-container'>
                <span className='type'>Sample Title</span>
                <span className='description'>Sample content.</span>
              </div>
            </div>
            
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default Services;